import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthData, User } from "../../types/user";
import axios from "axios";

// Базовий URL вашого API
// export const baseUrl = 'https://78ae-176-98-23-183.ngrok-free.app/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'https://yellow-hub-35b14f48a8c1.herokuapp.com/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4444/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'http://185.237.206.239:4444/';
// export const baseUrl = process.env.REACT_APP_API_URL || 'https://185.237.206.239:8443/';
export const baseUrl = 'https://yellow-hub-35b14f48a8c1.herokuapp.com/';


// Асинхронна функція для авторизації
// export const fetchAuth = createAsyncThunk('auth/login', async (params: any) => {
//     try {
//         const response = await axios.post<IAuthData>(baseUrl + `auth/login`, params);

//         // Збережіть токен у localStorage
//         localStorage.setItem('token', response.data.token);
//         // localStorage.setItem('userId', response.data.user._id);

//         // Встановіть токен у заголовки для майбутніх запитів
//         axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
//         // console.log(response.data);
//         return response.data;
//     } catch (e) {
//         console.log(e);
//     }
// });


export const fetchAuth = createAsyncThunk(
    "auth/login",
    async (params: any, { rejectWithValue }) => {
      try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/login`, params);
  
        // Збереження токена
        localStorage.setItem("token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
  
        return response.data;
      } catch (error: any) {
        console.error("Auth error:", error.response?.data?.message || "Помилка входу");
        return rejectWithValue(error.response?.data?.message || "Невірний номер телефону або пароль");
      }
    }
  );


// Асинхронна функція для реєстрації
export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (params: any) => {
    try {
        const response = await axios.post<IAuthData>(baseUrl + `auth/register`, params);

        // Збережіть токен у localStorage
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('userId', response.data.user._id);

        // Встановіть токен у заголовки для майбутніх запитів
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

        return response.data;
    } catch (e) {
        console.log(e);
    }
});


export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
    try {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Token not found');
        }

        // Відправка токена у форматі JSON через POST
        const response = await axios.post(baseUrl + 'auth/getUser', { token }, { 
            headers: {
                'Content-Type': 'application/json' // Вказуємо, що відправляємо JSON
            },
        });

        // console.log('Sending POST request to:', baseUrl + 'auth/getUser');
        // console.log('Token being used:', token);
        // console.log(response.data)

        return response.data;
    } catch (e) {
        console.error('Error fetching user data:', e);
    }
});

export const updateUserData = createAsyncThunk(
    "auth/updateUserData",
    async (updatedData: Record<string, any>, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("Token not found");

            // console.log("Token:", token);
            // console.log("Updated Data:", updatedData);

            const response = await axios.put(
                `${baseUrl}auth/updateUser`,
                updatedData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            // console.log("Response Data:", response.data);
            return response.data.user;
        } catch (error: any) {
            console.error("Error Response:", error.response);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


export const sendTeacherApplication = createAsyncThunk(
    'auth/sendTeacherApplication',
    async (applicationData: Record<string, any>, { rejectWithValue }) => {
      try {
        const response = await axios.post(baseUrl + `applications/teachers`, applicationData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data; // Повертаємо успішну відповідь
      } catch (error: any) {
        console.error('Error sending application:', error.response);
        return rejectWithValue(error.response?.data || 'Не вдалося надіслати заявку.');
      }
    }
  );




// Функція виходу
export const logout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
};

// interface IUserState {
//     data: null | IAuthData;
//     status: string;
//     isAuthenticated: boolean; // Додаємо поле для перевірки авторизації
// }

// const initialState: IUserState = {
//     data: null,
//     status: '',
//     isAuthenticated: false // Початково користувач не авторизований
// };

interface IUserState {
    data: null | IAuthData;
    status: string;
    isAuthenticated: boolean;
    errorMessage: string | null;
}

const initialState: IUserState = {
    data: null,
    status: '',
    isAuthenticated: false,
    errorMessage: null, // Додаємо нове поле
};



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetData: (state) => {
            state.data = null;
            state.isAuthenticated = false;
        },
        setAuthenticated: (state) => {
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        logout: (state) => {
            // Скидаємо дані стану при логауті
            state.data = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: {
        // [fetchAuth.pending.type]: (state) => {
        //     state.status = '';
        //     state.status = 'loading';
        //     state.data = null;
        // },
        // [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
        //     state.status = '';
        //     state.status = 'loaded';
        //     state.data = action.payload;
        //     state.isAuthenticated = true; // Встановлюємо авторизацію в true
        // },
        // [fetchAuth.rejected.type]: (state) => {
        //     state.status = '';
        //     state.status = 'error';
        //     state.data = null;
        //     state.isAuthenticated = false;
        // },

        [fetchAuth.pending.type]: (state) => {
            state.status = "loading";
            state.errorMessage = null; // Очищуємо попередні помилки
        },
        [fetchAuth.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = "loaded";
            state.data = action.payload;
            state.isAuthenticated = true;
            state.errorMessage = null; // При успішному вході очищуємо помилку
        },
        [fetchAuth.rejected.type]: (state, action) => {
            state.status = "error";
            state.data = null;
            state.isAuthenticated = false;
            state.errorMessage = action.payload as string; // Записуємо повідомлення про помилку
        },

        

        [fetchRegister.pending.type]: (state) => {
            state.status = '';
            state.status = 'loading';
            state.data = null;
        },
        [fetchRegister.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = '';
            state.status = 'loaded';
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchRegister.rejected.type]: (state) => {
            state.status = '';
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [fetchUserData.pending.type]: (state) => {
            state.status = '';
            state.status = 'loading';
        },
        [fetchUserData.fulfilled.type]: (state, action: PayloadAction<IAuthData>) => {
            state.status = '';
            state.status = 'loaded';
            // console.log(action.payload)
            state.data = action.payload;
            state.isAuthenticated = true; // Встановлюємо авторизацію в true
        },
        [fetchUserData.rejected.type]: (state) => {
            state.status = '';
            state.status = 'error';
            state.data = null;
            state.isAuthenticated = false;
        },
        [updateUserData.pending.type]: (state) => {
            state.status = '';
            state.status = 'loading'; // Починаємо оновлення
        },
        [updateUserData.fulfilled.type]: (state, action: PayloadAction<Partial<User>>) => {
            state.status = '';
            state.status = 'loaded';
            if (state.data) {
                state.data.user = { ...state.data.user, ...action.payload };
            }
        },
        [updateUserData.rejected.type]: (state) => {
            state.status = '';
            state.status = 'error'; // Помилка під час оновлення
        },
        [sendTeacherApplication.pending.type]: (state) => {
            state.status = 'loading'; // Стан завантаження
          },
          [sendTeacherApplication.fulfilled.type]: (state) => {
            state.status = 'loaded'; // Стан успішного завершення
          },
          [sendTeacherApplication.rejected.type]: (state) => {
            state.status = 'error'; // Стан помилки
          },
    }
});

export default authSlice.reducer;
export const { resetData, setAuthenticated } = authSlice.actions;