// import React, { useEffect, useRef, useState } from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import SidebarLinkGroup from './SidebarLinkGroup';
// import axios from 'axios';
// import { useAppSelector } from '../../../hooks/reducer';

// interface SidebarProps {
//   sidebarOpen: boolean;
//   setSidebarOpen: (arg: boolean) => void;
// }

// const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
//   const isAdmin = useAppSelector((state) => state.authReducer.data?.user?.isAdmin);
//   const location = useLocation();
//   const { pathname } = location;

//   const trigger = useRef<HTMLButtonElement>(null);
//   const sidebar = useRef<HTMLDivElement>(null);

//   const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
//   const [sidebarExpanded, setSidebarExpanded] = useState(
//     storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
//   );

//   // Закриття сайдбару при кліку поза ним
//   useEffect(() => {
//     const clickHandler = ({ target }: MouseEvent) => {
//       if (!sidebar.current || !trigger.current) return;
//       if (
//         !sidebarOpen ||
//         sidebar.current.contains(target as Node) ||
//         trigger.current.contains(target as Node)
//       )
//         return;
//       setSidebarOpen(false);
//     };
//     document.addEventListener('click', clickHandler);
//     return () => document.removeEventListener('click', clickHandler);
//   }, [sidebarOpen, setSidebarOpen]);

//   // Закриття при натисканні Esc
//   useEffect(() => {
//     const keyHandler = ({ keyCode }: KeyboardEvent) => {
//       if (!sidebarOpen || keyCode !== 27) return;
//       setSidebarOpen(false);
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => document.removeEventListener('keydown', keyHandler);
//   }, [sidebarOpen, setSidebarOpen]);

//   // Збереження стану розширення сайдбару в localStorage
//   useEffect(() => {
//     localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
//     if (sidebarExpanded) {
//       document.body.classList.add('sidebar-expanded');
//     } else {
//       document.body.classList.remove('sidebar-expanded');
//     }
//   }, [sidebarExpanded]);

//   // Функція логауту
//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     delete axios.defaults.headers.common['Authorization'];
//     window.location.reload();
//   };

//   return (
//     <aside
//       ref={sidebar}
//       className={`fixed left-0 top-0 z-50 flex h-screen w-64 flex-col overflow-y-auto bg-gradient-to-tl from-orange-600 to-orange-800 shadow-lg duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
//         sidebarOpen ? 'translate-x-0' : '-translate-x-full'
//       }`}
//     >
//       {/* SIDEBAR HEADER */}
//       <div className="flex items-center justify-between gap-2 px-6 py-4 border-b border-white/20">
//         <NavLink to="/" className="flex items-center">
//           <img
//             src="/assets/Images/yellow_logo_pl.png"
//             alt="Logo"
//             className="h-10 w-auto mt-2 rounded-lg"
//           />
//         </NavLink>
//         <button
//           ref={trigger}
//           onClick={() => setSidebarOpen(!sidebarOpen)}
//           aria-controls="sidebar"
//           aria-expanded={sidebarOpen}
//           className="lg:hidden p-2 focus:outline-none focus:ring"
//           aria-label="Toggle Sidebar"
//         >
//           <svg
//             className="fill-current text-white"
//             width="20"
//             height="18"
//             viewBox="0 0 20 18"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
//               fill=""
//             />
//           </svg>
//         </button>
//       </div>
//       {/* END SIDEBAR HEADER */}

//       <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar duration-300 ease-linear">
//         <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
//           {/* Для користувача (студент) */}
//           {!isAdmin ? (
//             <div>
//               <h3 className="mb-4 ml-4 text-xl font-medium text-gray-200">MENU</h3>
//               <ul className="mb-6 flex flex-col gap-1.5">
//                 <SidebarLinkGroup
//                   activeCondition={pathname === '/forms' || pathname.includes('forms')}
//                 >
//                   {(handleClick, open) => (
//                     <>
//                       <NavLink
//                         to="#"
//                         className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-gray-200 duration-300 ease-in-out hover:bg-white/30 ${
//                           (pathname === '/forms' || pathname.includes('forms')) && 'bg-white/30'
//                         }`}
//                         onClick={(e) => {
//                           e.preventDefault();
//                           sidebarExpanded ? handleClick() : setSidebarExpanded(true);
//                         }}
//                       >
//                         <svg
//                           className="fill-current text-white"
//                           width="18"
//                           height="18"
//                           viewBox="0 0 18 18"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             d="M1.43425 7.5093H2.278C2.44675 7.5093 2.55925 7.3968 2.58737 7.31243L2.98112 6.32805H5.90612L6.27175 7.31243C6.328 7.48118 6.46862 7.5093 6.58112 7.5093H7.453C7.76237 7.48118 7.87487 7.25618 7.76237 7.03118L5.428 1.4343C5.37175 1.26555 5.3155 1.23743 5.14675 1.23743H3.88112C3.76862 1.23743 3.59987 1.29368 3.57175 1.4343L1.153 7.08743C1.0405 7.2843 1.20925 7.5093 1.43425 7.5093Z"
//                             fill=""
//                           />
//                           <path
//                             d="M4.47175 2.98118L5.3155 5.17493H3.59987L4.47175 2.98118Z"
//                             fill=""
//                           />
//                           <path
//                             d="M10.1249 2.5031H16.8749C17.2124 2.5031 17.5218 2.22185 17.5218 1.85623C17.5218 1.4906 17.2405 1.20935 16.8749 1.20935H10.1249C9.7874 1.20935 9.47803 1.4906 9.47803 1.85623C9.47803 2.22185 9.75928 2.5031 10.1249 2.5031Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.8749 6.21558H10.1249C9.7874 6.21558 9.47803 6.49683 9.47803 6.86245C9.47803 7.22808 9.75928 7.50933 10.1249 7.50933H16.8749C17.2124 7.50933 17.5218 7.22808 17.5218 6.86245C17.5218 6.49683 17.2124 6.21558 16.8749 6.21558Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.875 11.1656H1.77187C1.43438 11.1656 1.125 11.4469 1.125 11.8125C1.125 12.1781 1.40625 12.4594 1.77187 12.4594H16.875C17.2125 12.4594 17.5219 12.1781 17.5219 11.8125C17.5219 11.4469 17.2125 11.1656 16.875 11.1656Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.875 16.1156H1.77187C1.43438 16.1156 1.125 16.3969 1.125 16.7625C1.125 17.1281 1.40625 17.4094 1.77187 17.4094H16.875C17.2125 17.4094 17.5219 17.1281 17.5219 16.7625C17.5219 16.3969 17.2125 16.1156 16.875 16.1156Z"
//                             fill="white"
//                           />
//                         </svg>
//                         <span className="ml-2 text-white">Курси</span>
//                         <svg
//                           className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current text-white transition-transform duration-200 ${
//                             open ? 'rotate-180' : ''
//                           }`}
//                           width="20"
//                           height="20"
//                           viewBox="0 0 20 20"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             fillRule="evenodd"
//                             clipRule="evenodd"
//                             d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
//                             fill=""
//                           />
//                         </svg>
//                       </NavLink>
//                       <div className={`overflow-hidden transition-all duration-200 ${!open ? 'max-h-0' : 'max-h-40'}`}>
//                         <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
//                           <li>
//                             <NavLink
//                               to="/course-grid-page"
//                               className={({ isActive }) =>
//                                 `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium text-gray-300 duration-300 ease-in-out hover:bg-white/10 hover:text-white ${
//                                   isActive ? 'text-white bg-white/10' : ''
//                                 }`
//                               }
//                             >
//                               <span>Всі курси</span>
//                             </NavLink>
//                           </li>
//                           <li className="mt-0">
//                             <NavLink
//                               to="/my-courselist"
//                               className={({ isActive }) =>
//                                 `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium text-gray-300 duration-300 ease-in-out hover:bg-white/10 hover:text-white ${
//                                   isActive ? 'text-white bg-white/10' : ''
//                                 }`
//                               }
//                             >
//                               <span>Мої курси</span>
//                             </NavLink>
//                           </li>
//                         </ul>
//                       </div>
//                     </>
//                   )}
//                 </SidebarLinkGroup>
//               </ul>
//             </div>
//           ) : (
//             /* Для адміністратора/викладача */
//             <div>
//               <h3 className="mb-4 ml-4 text-xl font-medium text-gray-200">TEACHER MENU</h3>
//               <ul className="mb-6 flex flex-col gap-1.5">
//                 <SidebarLinkGroup
//                   activeCondition={pathname === '/forms' || pathname.includes('forms')}
//                 >
//                   {(handleClick, open) => (
//                     <>
//                       <NavLink
//                         to="#"
//                         className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-gray-200 duration-300 ease-in-out hover:bg-white/30 ${
//                           (pathname === '/forms' || pathname.includes('forms')) && 'bg-white/30'
//                         }`}
//                         onClick={(e) => {
//                           e.preventDefault();
//                           sidebarExpanded ? handleClick() : setSidebarExpanded(true);
//                         }}
//                       >
//                         <svg
//                           className="fill-current text-white"
//                           width="18"
//                           height="18"
//                           viewBox="0 0 18 18"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             d="M1.43425 7.5093H2.278C2.44675 7.5093 2.55925 7.3968 2.58737 7.31243L2.98112 6.32805H5.90612L6.27175 7.31243C6.328 7.48118 6.46862 7.5093 6.58112 7.5093H7.453C7.76237 7.48118 7.87487 7.25618 7.76237 7.03118L5.428 1.4343C5.37175 1.26555 5.3155 1.23743 5.14675 1.23743H3.88112C3.76862 1.23743 3.59987 1.29368 3.57175 1.4343L1.153 7.08743C1.0405 7.2843 1.20925 7.5093 1.43425 7.5093Z"
//                             fill=""
//                           />
//                           <path
//                             d="M4.47175 2.98118L5.3155 5.17493H3.59987L4.47175 2.98118Z"
//                             fill=""
//                           />
//                           <path
//                             d="M10.1249 2.5031H16.8749C17.2124 2.5031 17.5218 2.22185 17.5218 1.85623C17.5218 1.4906 17.2405 1.20935 16.8749 1.20935H10.1249C9.7874 1.20935 9.47803 1.4906 9.47803 1.85623C9.47803 2.22185 9.75928 2.5031 10.1249 2.5031Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.8749 6.21558H10.1249C9.7874 6.21558 9.47803 6.49683 9.47803 6.86245C9.47803 7.22808 9.75928 7.50933 10.1249 7.50933H16.8749C17.2124 7.50933 17.5218 7.22808 17.5218 6.86245C17.5218 6.49683 17.2124 6.21558 16.8749 6.21558Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.875 11.1656H1.77187C1.43438 11.1656 1.125 11.4469 1.125 11.8125C1.125 12.1781 1.40625 12.4594 1.77187 12.4594H16.875C17.2125 12.4594 17.5219 12.1781 17.5219 11.8125C17.5219 11.4469 17.2125 11.1656 16.875 11.1656Z"
//                             fill=""
//                           />
//                           <path
//                             d="M16.875 16.1156H1.77187C1.43438 16.1156 1.125 16.3969 1.125 16.7625C1.125 17.1281 1.40625 17.4094 1.77187 17.4094H16.875C17.2125 17.4094 17.5219 17.1281 17.5219 16.7625C17.5219 16.3969 17.2125 16.1156 16.875 16.1156Z"
//                             fill="white"
//                           />
//                         </svg>
//                         <span className="ml-2 text-white">Курси</span>
//                         <svg
//                           className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current text-white transition-transform duration-200 ${
//                             open ? 'rotate-180' : ''
//                           }`}
//                           width="20"
//                           height="20"
//                           viewBox="0 0 20 20"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             fillRule="evenodd"
//                             clipRule="evenodd"
//                             d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
//                             fill=""
//                           />
//                         </svg>
//                       </NavLink>
//                       <div className={`overflow-hidden transition-all duration-200 ${!open ? 'max-h-0' : 'max-h-56'}`}>
//                         <ul className="mt-4 mb-5.5  flex flex-col gap-2 pl-6">
//                           <li>
//                             <NavLink
//                               to="/course-grid-page"
//                               className={({ isActive }) =>
//                                 `group relative h-9 flex items-center gap-2.5 rounded-md px-4  font-medium text-gray-300 duration-300 ease-in-out hover:bg-white/10 hover:text-white ${
//                                   isActive ? 'text-white bg-white/10' : ''
//                                 }`
//                               }
//                             >
//                               <span>Всі курси</span>
//                             </NavLink>
//                           </li>
//                           <li className="mt-0">
//                             <NavLink
//                               to="/my-courselist"
//                               className={({ isActive }) =>
//                                 `group relative flex  items-center py-2 gap-2.5 rounded-md px-4 font-medium text-gray-300 duration-300 ease-in-out hover:bg-white/10 hover:text-white ${
//                                   isActive ? 'text-white bg-white/10' : ''
//                                 }`
//                               }
//                             >
//                               <span>Мої курси</span>
//                             </NavLink>
//                           </li>
//                           <li className="mt-0">
//                             <NavLink
//                               to="/courseCreate"
//                               className={({ isActive }) =>
//                                 `group relative flex  items-center py-2 gap-2.5 rounded-md px-4 font-medium text-gray-300 duration-300 ease-in-out hover:bg-white/10 hover:text-white ${
//                                   isActive ? 'text-white bg-white/10' : ''
//                                 }`
//                               }
//                             >
//                               <span>Створити курс</span>
//                             </NavLink>
//                           </li>
//                         </ul>
//                       </div>
//                     </>
//                   )}
//                 </SidebarLinkGroup>
//               </ul>
//             </div>
//           )}
//           {/* OTHERS GROUP */}
//           <div>
//             <h3 className="mb-4 ml-4 text-xl font-medium text-gray-200">OTHERS</h3>
//             <ul className="mb-6 flex flex-col gap-1.5">
//               <li>
//                 <NavLink
//                   to="/settings"
//                   className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-gray-200 duration-300 ease-in-out hover:bg-white/30 ${
//                     pathname.includes('calendar') ? 'bg-white/30' : ''
//                   }`}
//                 >
//                   <svg
//                     className="fill-current text-white"
//                     width="22"
//                     height="22"
//                     viewBox="0 0 22 22"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M11 9.62499C8.42188 9.62499 6.35938 7.59687 6.35938 5.12187C6.35938 2.64687 8.42188 0.618744 11 0.618744C13.5781 0.618744 15.6406 2.64687 15.6406 5.12187C15.6406 7.59687 13.5781 9.62499 11 9.62499ZM11 2.16562C9.28125 2.16562 7.90625 3.50624 7.90625 5.12187C7.90625 6.73749 9.28125 8.07812 11 8.07812C12.7188 8.07812 14.0938 6.73749 14.0938 5.12187C14.0938 3.50624 12.7188 2.16562 11 2.16562Z"
//                       fill=""
//                     />
//                     <path
//                       d="M17.7719 21.4156H4.2281C3.5406 21.4156 2.9906 20.8656 2.9906 20.1781V17.0844C2.9906 13.7156 5.7406 10.9656 9.10935 10.9656H12.925C16.2937 10.9656 19.0437 13.7156 19.0437 17.0844V20.1781C19.0094 20.8312 18.4594 21.4156 17.7719 21.4156ZM4.53748 19.8687H17.4969V17.0844C17.4969 14.575 15.4344 12.5125 12.925 12.5125H9.07498C6.5656 12.5125 4.5031 14.575 4.5031 17.0844V19.8687H4.53748Z"
//                       fill=""
//                     />
//                   </svg>
//                   <span className="ml-2 text-white">Профіль</span>
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/courseCreate"
//                   onClick={handleLogout}
//                   className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-gray-200 duration-300 ease-in-out hover:bg-white/30 ${
//                     pathname.includes('calendar') ? 'bg-white/30' : ''
//                   }`}
//                 >
//                   <svg
//                     className="fill-current text-white"
//                     width="22"
//                     height="22"
//                     viewBox="0 0 22 22"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M15.5375 0.618744H11.6531C10.7594 0.618744 10.0031 1.37499 10.0031 2.26874V4.64062C10.0031 5.05312 10.3469 5.39687 10.7594 5.39687C11.1719 5.39687 11.55 5.05312 11.55 4.64062V2.23437C11.55 2.16562 11.5844 2.13124 11.6531 2.13124H15.5375C16.3625 2.13124 17.0156 2.78437 17.0156 3.60937V18.3562C17.0156 19.1812 16.3625 19.8344 15.5375 19.8344H11.6531C11.5844 19.8344 11.55 19.8 11.55 19.7312V17.3594C11.55 16.9469 11.2062 16.6031 10.7594 16.6031C10.3125 16.6031 10.0031 16.9469 10.0031 17.3594V19.7312C10.0031 20.625 10.7594 21.3812 11.6531 21.3812H15.5375C17.2219 21.3812 18.5625 20.0062 18.5625 18.3562V3.64374C18.5625 1.95937 17.1875 0.618744 15.5375 0.618744Z"
//                       fill=""
//                     />
//                     <path
//                       d="M6.05001 11.7563H12.2031C12.6156 11.7563 12.9594 11.4125 12.9594 11C12.9594 10.5875 12.6156 10.2438 12.2031 10.2438H6.08439L8.21564 8.07813C8.52501 7.76875 8.52501 7.2875 8.21564 6.97812C7.90626 6.66875 7.42501 6.66875 7.11564 6.97812L3.67814 10.4844C3.36876 10.7938 3.36876 11.275 3.67814 11.5844L7.11564 15.0906C7.25314 15.2281 7.45939 15.3312 7.66564 15.3312C7.87189 15.3312 8.04376 15.2625 8.21564 15.125C8.52501 14.8156 8.52501 14.3344 8.21564 14.025L6.05001 11.7563Z"
//                       fill=""
//                     />
//                   </svg>
//                   <span className="ml-2 text-white">Вийти з кабінету</span>
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </div>
//     </aside>
//   );
// };

// export default Sidebar;




// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++






import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import axios from 'axios';
import { useAppSelector } from '../../../hooks/reducer';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const isAdmin = useAppSelector((state) => state.authReducer.data?.user?.isAdmin);
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<HTMLButtonElement>(null);
  const sidebar = useRef<HTMLDivElement>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen, setSidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.body.classList.add('sidebar-expanded');
    } else {
      document.body.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    window.location.reload();
  };

  return (
    <aside
      ref={sidebar}
      className={`fixed left-0 top-0 z-50 flex h-screen w-64 flex-col overflow-y-auto bg-gradient-to-br from-orange-700 via-orange-600 to-orange-800 shadow-xl duration-300 ease-in-out dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {/* SIDEBAR HEADER */}
      <div className="flex items-center justify-between gap-2 px-6 py-5 border-b border-orange-500/20">
        <NavLink to="/" className="flex items-center">
          <img
            src="/assets/Images/yellow_logo_pl.png"
            alt="Logo"
            className="h-11 w-auto rounded-md shadow-sm"
          />
        </NavLink>
        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="lg:hidden p-2 rounded-md hover:bg-orange-500/30 transition-colors duration-200"
          aria-label="Toggle Sidebar"
        >
          <svg
            className="fill-current text-white"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* END SIDEBAR HEADER */}

      <div className="flex flex-col flex-1 overflow-y-auto no-scrollbar duration-300 ease-linear">
        <nav className="mt-6 px-4 lg:mt-8 lg:px-5">
          {/* Для користувача (студент) */}
          {!isAdmin ? (
            <div>
              <h3 className="mb-4 ml-4 text-lg font-semibold font-roboto text-white tracking-wide">MENU</h3>
              <ul className="mb-6 flex flex-col gap-2">
                <SidebarLinkGroup
                  activeCondition={pathname === '/forms' || pathname.includes('forms')}
                >
                  {(handleClick, open) => (
                    <>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-3 rounded-lg py-2.5 px-4 font-medium font-roboto text-white duration-300 ease-in-out hover:bg-orange-500/40 hover:scale-105 hover:shadow-lg ${
                          (pathname === '/forms' || pathname.includes('forms')) && 'bg-orange-500/50 shadow-md'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <svg
                          className="fill-current text-orange-200"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.43425 7.5093H2.278C2.44675 7.5093 2.55925 7.3968 2.58737 7.31243L2.98112 6.32805H5.90612L6.27175 7.31243C6.328 7.48118 6.46862 7.5093 6.58112 7.5093H7.453C7.76237 7.48118 7.87487 7.25618 7.76237 7.03118L5.428 1.4343C5.37175 1.26555 5.3155 1.23743 5.14675 1.23743H3.88112C3.76862 1.23743 3.59987 1.29368 3.57175 1.4343L1.153 7.08743C1.0405 7.2843 1.20925 7.5093 1.43425 7.5093Z"
                            fill=""
                          />
                          <path
                            d="M4.47175 2.98118L5.3155 5.17493H3.59987L4.47175 2.98118Z"
                            fill=""
                          />
                          <path
                            d="M10.1249 2.5031H16.8749C17.2124 2.5031 17.5218 2.22185 17.5218 1.85623C17.5218 1.4906 17.2405 1.20935 16.8749 1.20935H10.1249C9.7874 1.20935 9.47803 1.4906 9.47803 1.85623C9.47803 2.22185 9.75928 2.5031 10.1249 2.5031Z"
                            fill=""
                          />
                          <path
                            d="M16.8749 6.21558H10.1249C9.7874 6.21558 9.47803 6.49683 9.47803 6.86245C9.47803 7.22808 9.75928 7.50933 10.1249 7.50933H16.8749C17.2124 7.50933 17.5218 7.22808 17.5218 6.86245C17.5218 6.49683 17.2124 6.21558 16.8749 6.21558Z"
                            fill=""
                          />
                          <path
                            d="M16.875 11.1656H1.77187C1.43438 11.1656 1.125 11.4469 1.125 11.8125C1.125 12.1781 1.40625 12.4594 1.77187 12.4594H16.875C17.2125 12.4594 17.5219 12.1781 17.5219 11.8125C17.5219 11.4469 17.2125 11.1656 16.875 11.1656Z"
                            fill=""
                          />
                          <path
                            d="M16.875 16.1156H1.77187C1.43438 16.1156 1.125 16.3969 1.125 16.7625C1.125 17.1281 1.40625 17.4094 1.77187 17.4094H16.875C17.2125 17.4094 17.5219 17.1281 17.5219 16.7625C17.5219 16.3969 17.2125 16.1156 16.875 16.1156Z"
                            fill=""
                          />
                        </svg>
                        <span className="ml-2">Курси</span>
                        <svg
                          className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current text-orange-200 transition-transform duration-300 ease-in-out ${
                            open ? 'rotate-180' : ''
                          }`}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                            fill=""
                          />
                        </svg>
                      </NavLink>
                      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${!open ? 'max-h-0' : 'max-h-40'}`}>
                        <ul className="mt-2 mb-4 flex flex-col gap-1 pl-6">
                          <li>
                            <NavLink
                              to="/course-grid-page"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium font-roboto text-white duration-200 ease-in-out hover:bg-orange-500/30 hover:scale-105 hover:shadow-sm ${
                                  isActive ? 'bg-orange-500/40 shadow-md' : ''
                                }`
                              }
                            >
                              <span>Всі курси</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/my-courselist"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium font-roboto text-white duration-200 ease-in-out hover:bg-orange-500/30 hover:scale-105 hover:shadow-sm ${
                                  isActive ? 'bg-orange-500/40 shadow-md' : ''
                                }`
                              }
                            >
                              <span>Мої курси</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </SidebarLinkGroup>
              </ul>
            </div>
          ) : (
            /* Для адміністратора/викладача */
            <div>
              <h3 className="mb-4 ml-4 text-lg font-semibold font-roboto text-white tracking-wide">TEACHER MENU</h3>
              <ul className="mb-6 flex flex-col gap-2">
                <SidebarLinkGroup
                  activeCondition={pathname === '/forms' || pathname.includes('forms')}
                >
                  {(handleClick, open) => (
                    <>
                      <NavLink
                        to="#"
                        className={`group relative flex items-center gap-3 rounded-lg py-2.5 px-4 font-medium font-roboto text-white duration-300 ease-in-out hover:bg-orange-500/40 hover:scale-105 hover:shadow-lg ${
                          (pathname === '/forms' || pathname.includes('forms')) && 'bg-orange-500/50 shadow-md'
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <svg
                          className="fill-current text-orange-200"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.43425 7.5093H2.278C2.44675 7.5093 2.55925 7.3968 2.58737 7.31243L2.98112 6.32805H5.90612L6.27175 7.31243C6.328 7.48118 6.46862 7.5093 6.58112 7.5093H7.453C7.76237 7.48118 7.87487 7.25618 7.76237 7.03118L5.428 1.4343C5.37175 1.26555 5.3155 1.23743 5.14675 1.23743H3.88112C3.76862 1.23743 3.59987 1.29368 3.57175 1.4343L1.153 7.08743C1.0405 7.2843 1.20925 7.5093 1.43425 7.5093Z"
                            fill=""
                          />
                          <path
                            d="M4.47175 2.98118L5.3155 5.17493H3.59987L4.47175 2.98118Z"
                            fill=""
                          />
                          <path
                            d="M10.1249 2.5031H16.8749C17.2124 2.5031 17.5218 2.22185 17.5218 1.85623C17.5218 1.4906 17.2405 1.20935 16.8749 1.20935H10.1249C9.7874 1.20935 9.47803 1.4906 9.47803 1.85623C9.47803 2.22185 9.75928 2.5031 10.1249 2.5031Z"
                            fill=""
                          />
                          <path
                            d="M16.8749 6.21558H10.1249C9.7874 6.21558 9.47803 6.49683 9.47803 6.86245C9.47803 7.22808 9.75928 7.50933 10.1249 7.50933H16.8749C17.2124 7.50933 17.5218 7.22808 17.5218 6.86245C17.5218 6.49683 17.2124 6.21558 16.8749 6.21558Z"
                            fill=""
                          />
                          <path
                            d="M16.875 11.1656H1.77187C1.43438 11.1656 1.125 11.4469 1.125 11.8125C1.125 12.1781 1.40625 12.4594 1.77187 12.4594H16.875C17.2125 12.4594 17.5219 12.1781 17.5219 11.8125C17.5219 11.4469 17.2125 11.1656 16.875 11.1656Z"
                            fill=""
                          />
                          <path
                            d="M16.875 16.1156H1.77187C1.43438 16.1156 1.125 16.3969 1.125 16.7625C1.125 17.1281 1.40625 17.4094 1.77187 17.4094H16.875C17.2125 17.4094 17.5219 17.1281 17.5219 16.7625C17.5219 16.3969 17.2125 16.1156 16.875 16.1156Z"
                            fill=""
                          />
                        </svg>
                        <span className="ml-2">Курси</span>
                        <svg
                          className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current text-orange-200 transition-transform duration-300 ease-in-out ${
                            open ? 'rotate-180' : ''
                          }`}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                            fill=""
                          />
                        </svg>
                      </NavLink>
                      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${!open ? 'max-h-0' : 'max-h-56'}`}>
                        <ul className="mt-2 mb-4 flex flex-col gap-1 pl-6">
                          <li>
                            <NavLink
                              to="/course-grid-page"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium font-roboto text-white duration-200 ease-in-out hover:bg-orange-500/30 hover:scale-105 hover:shadow-sm ${
                                  isActive ? 'bg-orange-500/40 shadow-md' : ''
                                }`
                              }
                            >
                              <span>Всі курси</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/my-courselist"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium font-roboto text-white duration-200 ease-in-out hover:bg-orange-500/30 hover:scale-105 hover:shadow-sm ${
                                  isActive ? 'bg-orange-500/40 shadow-md' : ''
                                }`
                              }
                            >
                              <span>Мої курси</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/courseCreate"
                              className={({ isActive }) =>
                                `group relative flex items-center gap-2.5 rounded-md px-4 py-2 font-medium font-roboto text-white duration-200 ease-in-out hover:bg-orange-500/30 hover:scale-105 hover:shadow-sm ${
                                  isActive ? 'bg-orange-500/40 shadow-md' : ''
                                }`
                              }
                            >
                              <span>Створити курс</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </SidebarLinkGroup>
              </ul>
            </div>
          )}
          {/* OTHERS GROUP */}
          <div>
            <h3 className="mb-4 ml-4 text-lg font-semibold font-roboto text-white tracking-wide">OTHERS</h3>
            <ul className="mb-6 flex flex-col gap-2">
              <li>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    `group relative flex items-center gap-3 rounded-lg py-2.5 px-4 font-medium font-roboto text-white duration-300 ease-in-out hover:bg-orange-500/40 hover:scale-105 hover:shadow-lg ${
                      isActive ? 'bg-orange-500/50 shadow-md' : ''
                    }`
                  }
                >
                  <svg
                    className="fill-current text-orange-200"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 9.62499C8.42188 9.62499 6.35938 7.59687 6.35938 5.12187C6.35938 2.64687 8.42188 0.618744 11 0.618744C13.5781 0.618744 15.6406 2.64687 15.6406 5.12187C15.6406 7.59687 13.5781 9.62499 11 9.62499ZM11 2.16562C9.28125 2.16562 7.90625 3.50624 7.90625 5.12187C7.90625 6.73749 9.28125 8.07812 11 8.07812C12.7188 8.07812 14.0938 6.73749 14.0938 5.12187C14.0938 3.50624 12.7188 2.16562 11 2.16562Z"
                      fill=""
                    />
                    <path
                      d="M17.7719 21.4156H4.2281C3.5406 21.4156 2.9906 20.8656 2.9906 20.1781V17.0844C2.9906 13.7156 5.7406 10.9656 9.10935 10.9656H12.925C16.2937 10.9656 19.0437 13.7156 19.0437 17.0844V20.1781C19.0094 20.8312 18.4594 21.4156 17.7719 21.4156ZM4.53748 19.8687H17.4969V17.0844C17.4969 14.575 15.4344 12.5125 12.925 12.5125H9.07498C6.5656 12.5125 4.5031 14.575 4.5031 17.0844V19.8687H4.53748Z"
                      fill=""
                    />
                  </svg>
                  <span className="ml-2">Профіль</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/courseCreate"
                  onClick={handleLogout}
                  className={`group relative flex items-center gap-3 rounded-lg py-2.5 px-4 font-medium font-roboto text-white duration-300 ease-in-out hover:bg-orange-500/40 hover:scale-105 hover:shadow-lg`}
                >
                  <svg
                    className="fill-current text-orange-200"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 0H9C8.5875 0 8.25 0.3375 8.25 0.75V2.25C8.25 2.6625 8.5875 3 9 3C9.4125 3 9.75 2.6625 9.75 2.25V1.5H13.5C14.325 1.5 15 2.175 15 3V15C15 15.825 14.325 16.5 13.5 16.5H9C8.5875 16.5 8.25 16.8375 8.25 17.25V18C8.25 18.4125 8.5875 18.75 9 18.75H13.5C15.4875 18.75 17.25 16.9875 17.25 15V3C17.25 1.0125 15.4875 0 13.5 0Z"
                      fill=""
                    />
                    <path
                      d="M5.25 9H12C12.4125 9 12.75 8.6625 12.75 8.25C12.75 7.8375 12.4125 7.5 12 7.5H5.25L7.125 5.625C7.4625 5.2875 7.4625 4.7625 7.125 4.425C6.7875 4.0875 6.2625 4.0875 5.925 4.425L1.875 8.475C1.5375 8.8125 1.5375 9.3375 1.875 9.675L5.925 13.725C6.2625 14.0625 6.7875 14.0625 7.125 13.725C7.4625 13.3875 7.4625 12.8625 7.125 12.525L5.25 10.5H12C12.4125 10.5 12.75 10.1625 12.75 9.75C12.75 9.3375 12.4125 9 12 9H5.25Z"
                      fill=""
                    />
                  </svg>
                  <span className="ml-2">Вийти з кабінету</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;