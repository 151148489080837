import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../components/NewView/Common/Loader";
const DonateComponent = React.lazy(() =>
  import("../../components/NewView/RootComponents/DonateComponent")
);
const ContactPage = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
            YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education
            Course React Template"
          />
        </Helmet>
        <DonateComponent />
      </Suspense>
    </Fragment>
  );
};

export default ContactPage;
