import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../../../components/NewView/Common/Loader";
const PublicCourseTargetDetailsComponent = React.lazy(() =>
  import("../../../../components/NewView/RootComponents/courses/publicCourses/RefactorPublicCourseTargetDetailsComponent")
);
const PublicCourseTargetPage = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
          YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course
            React Template"
          />
        </Helmet>
        <PublicCourseTargetDetailsComponent />
      </Suspense>
    </Fragment>
  );
};

export default PublicCourseTargetPage;
