import React, { Fragment, Suspense } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../../components/NewView/Common/Loader";
const CourseCallComponent = React.lazy(() =>
  import("../../../components/NewView/RootComponents/courses/CourseCallComponent")
);
const CourseCallPage = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Helmet>
          <title>
          YellowHub
          </title>
          <meta
            name="description"
            content="Next LMS - Professional LMS Online Education Course
            React Template"
          />
        </Helmet>
        <CourseCallComponent />
      </Suspense>
    </Fragment>
  );
};

export default CourseCallPage;
